<template>
	<PopupLayer>
		<template
			v-slot:body
		>
			<div class="bg-white width-320 ma-auto full-height flex-column justify-space-between " style="max-height: 480px">
				<div class="pa-10 justify-space-between under-line">
					<h4>결제 완료</h4>
					<v-icon
						@click="toList"
					>mdi-close</v-icon>
				</div>

				<div
					class="full-height overflow-y-auto position-relative pa-10"
				>

					<div class="pa-20 ptb-50">
						<h6 class="text-center"> 결제가 완료되었습니다. </h6>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class=" ">상품명</div>
							<div class="">{{ payment_info.pdt_name }}</div>
						</div>
						<div class="mt-10 flex-row justify-space-between items-center">
							<div class=" ">결제금액</div>
							<div class="font-weight-bold">{{ payment_info.pdt_price | makeComma }}원</div>
						</div>
					</div>

					<div
						class="bg-none top-line radius-bottom-20 text-center"
					>
						<button
							@click="toList"
							class="btn btn-primary mr-10"
						><slot name="name-confirm">확인</slot></button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'PaymentResult'
	, components: {PopupLayer}
	, props: ['user', 'payment_info']
	, data: function(){
		return {

		}
	}
	, methods: {
		toList: function(){
			this.$emit('click')
		}
		, toNext: function(){
			this.$emit('click')
		}
	}
}
</script>