<template>

	<PaymentResult
		v-if="is_on_result"
		:user="user"
		:payment_info="item"

		@click="$emit('click')"
	></PaymentResult>
	<PopupLayer
		v-else
	>
		<template
			v-slot:body
		>
			<div class="bg-white width-320 ma-auto full-height flex-column justify-space-between " style="max-height: 480px">
				<div class="pa-10 justify-space-between under-line">
					<h6>추가 결제</h6>
					<v-icon @click="$emit('cancel')">mdi-close</v-icon>
				</div>
				<div
					class="full-height overflow-y-auto position-relative pa-10"
				>
					<div
						v-if="false"
						class=" flex-row justify-space-between">
						<button
							class="flex-1 btn  pa-10"
							:class="item.card_div_type == 'personal' ? 'btn-primary' : 'btn-primary-outline'"
							@click="item.card_div_type = 'personal'"
						>개인</button>
						<button
							class="flex-1 btn btn-primary-outline  pa-10"
							:class="item.card_div_type == 'business' ? 'btn-primary' : 'btn-primary-outline'"
							@click="item.card_div_type = 'business'"
						>법인</button>
					</div>
					<div>
						<select
							v-model="pg_uid"
							class="input-box"
						>
							<option value="">PG를 선택하세요</option>
							<template
								v-for="(shop, s_index) in items_pg"
							>
								<option
									v-if="shop.fee_status == 1"
									:key="'shop_' + s_index"
									:value="shop.uid"
								>{{ shop.pg_name }}</option>
							</template>
						</select>
					</div>
					<div class="mt-10">
						<label
						>카드번호</label>
						<div class="flex-row justify-center mt-10 box  pa-10 items-center">
							<div>
							<input
								v-model="item.card_number[0]"
								placeholder="0000"
								class="width-100 text-center"
								type="number"
								:rules="$rules.numeric(item.card_number, 0, 4)"
								@keyup="onFocus($event, 4, 'card_number_1')"
							/>
							</div>
							<div>
							<input
								v-model="item.card_number[1]"
								placeholder="0000"
								class="width-100 text-center"
								ref="card_number_1"
								type="password"
								:rules="$rules.numeric(item.card_number, 1, 4)"
								@keyup="onFocus($event, 4, 'card_number_2')"
							/>
							</div>
							<div>
							<input
								v-model="item.card_number[2]"
								placeholder="0000"
								class="width-100 text-center"
								ref="card_number_2"
								type="password"
								:rules="$rules.numeric(item.card_number, 2, 4)"
								@keyup="onFocus($event, 4, 'card_number_3')"
							/>
							</div>
							<div>
							<input
								v-model="item.card_number[3]"
								placeholder="0000"
								class="width-100 text-center"
								ref="card_number_3"
								type="number"
								:rules="$rules.numeric(item.card_number, 3, 4)"
								@keyup="onFocus($event, 4, 'valid_term_mm')"
							/>
								</div>
						</div>
					</div>
					<div class="mt-20">
						<div>
							<label
							>유효기간</label>
							<div class="mt-10 flex-row justify-space-betweens ">
								<input
									v-model="item.valid_term_mm"
									:rules="$rules.numeric(item, 'valid_term_mm', 2)"
									class="input-box mr-10 text-center"
									ref="valid_term_mm"
									placeholder="mm"
									@keyup="onFocus($event, 2, 'valid_term_year')"
								>
								<input
									v-model="item.valid_term_year"
									:rules="$rules.numeric(item, 'valid_term_year', 2)"
									class="input-box text-center"
									ref="valid_term_year"
									placeholder="yy"
									@keyup="onFocus($event, 2, 'card_pw')"
								>
							</div>
						</div>
					</div>
					<div class="mt-20 flex-row justify-space-between">
						<div class="flex-1 mr-10">
							<label
							>비밀번호</label>
							<input
								v-model="item.card_pw"
								type="password"
								class="mt-10 input-box text-center"
								placeholder="비밀번호 앞 2자리"
								ref="card_pw"
								:rules="$rules.numeric(item, 'card_pw', 2)"
								@keyup="onFocus($event, 2, 'installment')"
							>
						</div>
						<div class="flex-1 ">
							<label
							>할부</label>
							<select
								v-model="item.installment"
								class="mt-10 input-box text-center"
								ref="installment"
							>
								<option value="00">일시불</option>
								<option
									v-for="i in 10"
									:key="'installment_' + i"
									:value="('0' + (i + 1)).slice(-2)"
								>{{ i + 1}}개월</option>
							</select>
						</div>
					</div>
					<div
						v-if="item.card_div_type == 'personal'"
						class="mt-20"
					>
						<label
						>생년월일</label>
						<input
							v-model="item.auth_no"
							class="mt-10 input-box"
							ref="personal_biz_certfc_info"
							placeholder="예시) 190101"
							:rules="$rules.numeric(item, 'auth_no', 6)"
							@keyup="onFocus($event, 6, 'buyer_name')"
						>
					</div>
					<div
						v-else
						class="mt-20"
					>
						<label
						>사업자번호</label>
						<input
							v-model="item.auth_no"
							class="mt-10 input-box"
							ref="personal_biz_certfc_info"
							placeholder="사업자번호 10자리"
							:rules="$rules.numeric(item, 'auth_no', 10)"
							@keyup="onFocus($event, 10, 'buyer_name')"
						>
					</div>

					<div
						class="mt-20"
					>
						<label>주문자 명</label>
						<input
							v-model="item.buyer_name"
							class="mt-10 input-box"
							ref="buyer_name"
							placeholder="주문자명을 입력하세요"
							max="20"
						>
					</div>

					<div
						class="mt-20"
					>
						<label>주문자 연락처</label>
						<input
							v-model="item.buyer_tell"
							class="mt-10 input-box"
							ref="buyer_tell"
							placeholder="주문자 연락처를 입력하세요"
							max="10"
						>
					</div>

					<div
						class="mt-20"
					>
						<label>상품명</label>
						<input
							v-model="item.pdt_name"
							class="mt-10 input-box"
							ref="pdt_name"
							placeholder="상품명을 입력하세요"
							max="20"
						>
					</div>

					<div
						class="mt-20"
					>
						<label>결제금액</label>
						<input
							v-model="item.pdt_price"
							type="number"
							class="mt-10 input-box"
							ref="pdt_price"
							placeholder="결제금액을 입력하세요"
							:rules="$rules.max(item, 'pdt_price', 9)"
						>
					</div>
				</div>
				<div
					class="mt-auto"
				>
					<button
						v-if="is_confirm"
						class="btn btn-primary"
						:disabled="is_disabled"
						@click="postPayment"
					>결제요청</button>
					<button
						v-else
						class="btn btn-primary"
						@click="postCardConfirm"
					>카드 조회</button>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>
<script>
import PaymentResult from "./PaymentResult";
import PopupLayer from "../Layout/PopupLayer";
export default {
	name: 'PaymentItem'
	, components: {PopupLayer, PaymentResult}
	, props: ['user', 'payment_info']
	, data: function(){
		return {
			program: {
				name: '수기결제'
				, title: '수기결제'
				, bottom: false
				, top: false
			}
			, item: {
				card_number: []
				, card_div_type: 'personal'
				, installment: '00'
				, pdt_price: ''
				, pdt_name: ''
				, buyer_name: ''
				, auth_no: ''
				, shop_uid: this.payment_info.shop_uid
			}
			, is_on_result: false
			, is_confirm: true
			, items_shop: []
			, items_pg: []
			, pg_uid: ''
		}
	}
	, computed: {
		is_disabled: function(){
			let t = true

			if(this.card_number && this.valid_yymm && this.item.pdt_price && this.item.pdt_name ){
				t = false
			}
			return t
		}
		, card_number: function(){
			let t = this.item.card_number.join('')

			return t
		}
		, card_confirm_url: function(){
			let t = ''

			let domain = process.env.VUE_APP_DOMAIN_PAYMENT
			let dev = process.env.VUE_APP_DEV
			let server = process.env.VUE_APP_SERVER_PAYMENT
			let location = window.location.hostname

			if(location == domain){
				t = server
			}else{
				t = dev
			}
			t += '/inicis/postCardRequest'

			return t
		}
		, valid_yymm: function(){
			let t = ''
			t += this.item.valid_term_year + this.item.valid_term_mm
			return t
		}
		, shop_info: function(){

			let t = {}
			console.log('shop_info')
			this.items_shop.filter( (item) => {

				if(item.uid == this.$route.params.shop_uid){
					t = item
				}
			})
			return t
		}

	}
	, methods: {
		postPayment: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'post'
					, url: '/admin/postPayment'
					, data: {
						card_div_type: this.item.card_div_type
						, pdt_price: this.item.pdt_price
						, pdt_name: this.item.pdt_name
						, buyer_name: this.item.buyer_name
						, buyer_tell: this.item.buyer_tell
						, installment: this.item.installment
						, card_number: this.card_number
						, valid_yymm: this.valid_yymm
						, auth_no: this.item.auth_no
						, card_pw: this.item.card_pw
						, shop_uid: this.item.shop_uid
						, pg_uid: this.pg_uid
						, order_number: this.payment_info.order_number
					}
				})
				if(result.success){
					this.is_on_result = true
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onFocus: function(e, max, target){
			if(e.target.value.length >= max){
				this.$refs[target].focus()
			}
		}
		, postCardConfirm: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'post'
					, url: '/inicis/postCardRequest'
				})
				if(result.success){
					this.is_confirm = false
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPgList: async function(){
			try{
				this.$bus.$emit('on', true)
				let result = await this.$request.init({
					method: 'get'
					,url: 'management/getAvailablePgList'
					,data: {
						agency_type: 'A001003'
						, upper_id: this.payment_info.upper_id
						, agency_id: this.payment_info.member_id
					}
				})
				if(result.success){
					this.items_pg = result.data
				}else{
					throw result.message
				}
			}catch (e){
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, onMessage: function(e){
			console.log('on message', e.data.origin, window.location.href)

			let data = JSON.parse(e.data)
			console.log(data)

			if(data.type == 'cardConfirm'){

				try{

					if(data.result == 'success'){
						let auth = JSON.parse(data.data)
						this.item.buyer_name = auth.Name
						this.item.auth_no = (auth.Socialno).slice(-6)
						this.is_confirm = true
						this.$bus.$emit('notify', { type: 'success', message: data.message })
					}else if(data.result == 'cancel'){
						this.$router.push({ name: this.program.from})
						throw data.message
					}else{
						throw data.message
					}
				}catch (e){
					console.log('addEventListener', e)
					this.$bus.$emit('notify', { type: 'error', message: e })
				}
			}
		}

	}
	, created() {

		this.getPgList();
		window.removeEventListener('message', this.onMessage)
		setTimeout( () => {
			window.addEventListener('message', this.onMessage)
		}, 100)
	}
	, watch: {

	}
}
</script>