<template>
	<div>

		<div
			class="full-height flex-column"
		>
			<Search
				:search="item_search"
				:option="item_search_option"

				@change="getSearchData"
				@click="getSearchData"
			>
			</Search>

			<div
				class="mt-10 bg-white pa-10 full-height overflow-y-auto"
			>
				<div v-if="items.length > 0">
					<table class="table table-even">
						<colgroup>
							<col width="80px" />
							<col width="auto" />
							<col width="auto" />
							<col width="auto" />
							<col width="auto" />

							<col width="auto" />
							<col width="auto" />
						</colgroup>
						<thead>
						<tr>
							<th>
								<input
									type="checkbox"
								/>
							</th>
							<th>가맹점</th>
							<th>아이디</th>
							<th>결제 예정 금액</th>
							<th>결제 완료 금액</th>
							<th>결제상태</th>

							<th>등록일</th>
							<th>상세정보</th>
						</tr>
						</thead>
						<tbody>
						<tr
							v-for="item in history_list"
							:key="item.uid"
						>
							<td>
								<input
									type="checkbox"
								/>
							</td>
							<td>{{ item.shop_name }}</td>
							<td>{{ item.member_id }}</td>
							<td>{{ item.order_price | makeComma }}원</td>
							<td>{{ item.payment_amount | makeComma}}원</td>
							<td :class="'color-' + item.o_status_color">{{ item.o_status_name }}</td>
							<td>{{ item.wDate }}</td>
							<td><button @click="onDetail(item)" class="btn-inline btn-primary">상세보기</button></td>
						</tr>
						</tbody>
					</table>

					<Pagination
						:program="program"
						:align="'center'"
						:options="item_search"

						class="mt-auto"
						@click="getSearchData"
					></Pagination>
				</div>

				<Empty
					v-else
				></Empty>
			</div>
		</div>

		<PopupLayer
			v-if="is_on_detail"
		>
			<template
				v-slot:body
			>
				<div
					class="full-height flex-column justify-space-between bg-white"
				>
					<div
						class="under-line justify-space-between items-center pa-10-20 "
					>
						<h6>수기 결제 상세 내역</h6>
						<button
							@click="offDetail"
						><mdicon name="close-circle" /></button>
					</div>
					<div
						class="full-height flex-column pa-20 overflow-y-auto"
					>
						<table class="table table-even">
							<colgroup>
								<col width="80px" />
								<col width="120px" />
								<col width="120px" />
								<col width="120px" />
								<col width="150px" />
								<col width="150px" />
								<col width="auto" />
							</colgroup>
							<thead>
							<tr>
								<th>No</th>
								<th>PG</th>
								<th>결제 금액</th>
								<th>결제 상태</th>
								<th>예정 일시</th>
								<th>결제 일시</th>
								<th>메모</th>
							</tr>
							</thead>
							<tbody>
							<tr
								v-for="(detail, d_index) in item_detail.items"
								:key="'detail_' + d_index"
							>
								<td>{{ d_index + 1 }}</td>
								<td>{{ detail.pg_name }}</td>
								<td>{{ detail.pg_amount | makeComma }}원</td>
								<td :class="'color-' + detail.p_status_color">{{ detail.p_status_name }}</td>
								<td>{{ detail.sDate}}</td>
								<td>{{ detail.eDate}}</td>
								<td class="text-left" :inner-html.prop="detail.memo | nl2br"></td>
							</tr>
							</tbody>
						</table>
						<Empty
							v-if="item_detail.items.length == 0"
						></Empty>
						<div
							v-if="item_detail.order_price > item_detail.payment_amount"
							class="text-right mt-10"
						>
							<button
								@click="onPayment"
								class="btn-inline btn-primary"
							>추가 결제</button>
						</div>
						<div
							v-if="item_detail.order_price == item_detail.payment_amount && item_detail.o_status != 2"
							class="text-right mt-10"
						>
							<button
								@click="postPaymentComplete"
								class="btn-inline btn-primary"
							>완료 처리</button>
						</div>
					</div>
				</div>
			</template>
		</PopupLayer>

		<PaymentItem
			v-if="is_on_payment"
			:user="user"
			:payment_info="item_detail"

			@cancel="offPayment"
			@click="resultPayment"
		></PaymentItem>
	</div>
</template>
<script>
import Pagination from "../../components/Pagination";
import Search from "../Layout/Search";
import Empty from "../Layout/Empty";
import PopupLayer from "../Layout/PopupLayer";
import PaymentItem from "./PaymentItem";
export default {
	name: 'HistoryPayment'
	, components: {PaymentItem, PopupLayer, Empty, Search, Pagination}
	, props: ['Axios', 'rules', 'TOKEN', 'date', 'codes', 'user']
	, data: function(){
		return {
			program: {
				name: '수기 결제 목록'
				,top: true
				,title: true
			}
			, item_search: this.$storage.init({
				search_type: ''
				, search_value: ''
				, list_cnt: 10
				, page: 1
			})
			, item_search_option: {
				is_cnt: true
				,cnt: 0
				,tCnt: 0
				,search_type: [
					{ name: '아이디', column: '`order`.member_id'}
					,{ name: '상점명', column: 'shop_name'}
				]
			}
			, items: []
			, is_on_detail: false
			, item_detail: {}
			, is_on_payment: false
		}
	}
	, computed: {
		history_list: function(){
			return this.items.filter( (item) => {
				switch (item.o_status){
					case '1':
						item.o_status_name = '결제 대기'
						item.o_status_color = ''
						break;
					case '2':
						item.o_status_name = '결제 완료'
						item.o_status_color = 'green'
						break;
					case '3':
						item.o_status_name = '결제 실패'
						item.o_status_color = 'red'
						break;
				}

				item.items.filter( (pg) => {

					switch (pg.p_status){
						case '0':
							pg.p_status_name = '결제 대기'
							pg.p_status_color = ''
							break;
						case '1':
							pg.p_status_name = '결제 완료'
							pg.p_status_color = 'green'
							break;
						case '2':
							pg.p_status_name = '결제 실패'
							pg.p_status_color = 'red'
							break;
					}
				})
				return item
			})
		}
	}
	, methods: {
		getData: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'get'
					,url: 'admin/getHistoryPayment'
					,data: this.item_search
				})

				if(result.success){
					this.items = result.data.result
					this.$set(this.item_search, 'total_count', result.data.tCnt)
					this.item_search_option.tCnt = result.data.tCnt
					this.item_search_option.cnt = result.data.cnt
					this.$storage.setQuery(this.item_search)
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, getSearchData: function(page){
			if(page){
				this.item_search.page = page
			}
			this.getData()
		}
		, onDetail: function(item){
			this.item_detail = item
			this.is_on_detail = true
		}
		, offDetail: function(){
			this.item_detail = {}
			this.is_on_detail = false
		}
		, onPayment: function(){
			this.is_on_payment = true
		}
		, offPayment: function(){
			this.is_on_payment = false
		}
		, resultPayment: function(){
			this.getData()
			this.offPayment()
			this.offDetail()
		}
		, postPaymentComplete: async function(){
			this.$bus.$emit('on', true)
			try{
				const result = await this.$request.init({
					method: 'post'
					,url: 'admin/postPaymentComplete'
					,data: {
						order_number: this.item_detail.order_number
					}
				})

				if(result.success){
					await this.resultPayment()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch (e) {
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
}
</script>